// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query ExportAssignmentReport($slug: String!, $sorters: [DataGridSorterInput]) {
    export: exportAssignmentReport(slug: $slug, sorters: $sorters) {
      data,
      name
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { QUERY };
